<template>
    <section>
        <b-skeleton v-if="loading" />
        <Check 
            v-else
            :value="appointmentPrintables.print_statement_of_awareness"
            label="Declaração de ciência da realização de procedimento/exame" 
            @change="checked => updateAppointmentPrintables('print_statement_of_awareness', checked)"
        />
    </section>
</template>
<script>
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
    components: {
        Check: () => import('@/components/General/Check')
    },
    async mounted() {
        await this.getAppointmentPrintables()
    },
    data() {
        return {
            loading: false,
            clinic: getCurrentClinic(),
            appointmentPrintables: {
                print_statement_of_awareness: false
            }   
        }
    },
    methods: {
        async getAppointmentPrintables() {
            this.loading = true
            try {
                const { data } = await this.api.getAppointmentPrintables(this.clinic.id)
                this.appointmentPrintables = data
            } catch (error) {
                console.log(error)
            } finally {
                this.loading = false
            }
        },
        async updateAppointmentPrintables(key, checked) {
            this.appointmentPrintables[key] = checked
            try {
                await this.api.updateAppointmentPrintables(this.appointmentPrintables.id, { [key]: checked })
            } catch (error) {
                console.log(error)
                this.appointmentPrintables[key] = !checked
            }
            // this.$forceUpdate()
        }
    }
}
</script>
<style lang="scss" scoped>

</style>